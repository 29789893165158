<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)" class="top-selections">
			<div style="display: flex; flex-wrap: wrap; align-items: center">
				<div>
					<div class="netshortcut" @click="showStationSelectOverlay = true">Choose Stations</div>
					<div style="text-align: center">
						<input type="checkbox" id="gtin" v-model="groupStationsTogether" />
						<label for="gtin" style="margin-left: 5px; font-size: 11px;">Group</label>
					</div>
				</div>
			</div>
			<div>
				<p class="above-select-label">Statistic Type</p>
				<select class="select-styled" v-model="chosenStatistic">
					<option v-for="opt in statistics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Daypart</p>
				<select class="select-styled" v-model="chosenDaypart">
					<option v-for="opt in daypartOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Reporting Period</p>
				<select class="select-styled" v-model="chosenPeriod">
					<option v-for="opt in periodOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Distributor</p>
				<select class="select-styled" v-model="chosenDist">
					<option v-for="opt in distributors" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div v-if="chosenPeriod !== 'd'">
				<p class="above-select-label">Period Start</p>
				<select class="select-styled" v-model="startdayselect">
					<option v-for="opt in startdayselects" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div v-if="chosenPeriod !== 'd'">
				<p class="above-select-label">Period End</p>
				<select class="select-styled" v-model="enddayselect">
					<option v-for="opt in enddayselects" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>

			<div v-if="chosenPeriod === 'd'">
				<p class="above-select-label">Period Start</p>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="chosenStartDay" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>

			<div v-if="chosenPeriod === 'd'">
				<p class="above-select-label">Period End</p>
				<date-picker class="dater-tr" style="width: 200px;" v-model:value="chosenEndDay" valueType="format" format="YYYY-MM-DD"></date-picker>
			</div>

			<div style="display: flex; align-items: flex-end;">
				<span class="primary-btn" @click="getChartData">Fetch</span>
			</div>
		</div>

		<div v-if="showStationSelectOverlay" class="loader-overlay">
			<div class="stationselect-popup whitebg">
				<div class="popup-topbar">
					<span class="boldme">Choose Stations</span>
					<span class="closer" @click="showStationSelectOverlay = false"><i class="mdi mdi-close"></i></span>
				</div>
				<div style="width: 100%;" class="popup-insides">
					<div style="margin-bottom: 20px;">
						<div style="display: inline-block;">
							<div class="netshortcut" @click="selectAllStations('all')">All ARN</div>
						</div>
						<div style="display: inline-block;">
							<div class="netshortcut" @click="selectAllStations('metro')">All Metro</div>
						</div>
						<div style="display: inline-block;">
							<div class="netshortcut" @click="selectAllStations('regional')">All Regional</div>
						</div>
					</div>
					<div v-for="st in stationsOrdered" :key="st.id" style="display: inline-block; text-align: left; width: 250px; margin-bottom: 8px;">
						<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.id" :value="st" />
						<label style="margin-right: 15px;" :for="'stn'+st.id">{{st.nice}}</label>
					</div>
					<div style="display: flex; align-items: flex-end;">
						<span class="primary-btn" @click="getChartData">Fetch</span>
					</div>
				</div>
			</div>
		</div>

		<div class="chart-rows">
			<ChartHolderDash
					:charttitle="chartTitle"
					:source="chartsSource.streamstntrnd" :height="600">
				<template v-slot:default>
					<ApexLineWeeklyTrend :forcelinetype="true" :chartdata="chartsData.streamstntrnd" :cadence="chosenPeriod" filename="Streaming" :fetching="getChartsBtnWorking"></ApexLineWeeklyTrend>
				</template>
			</ChartHolderDash>
		</div>

		<div v-if="chartsData.streamstntrnd">
			<ALTable tblclasses="noborderv" :excel="true" excelfilename="streaming"
				:cols="tableColumnHeaders"
				:rowdata="rowData"
			></ALTable>
		</div>

		<StreamNotesReg></StreamNotesReg>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineWeeklyTrend from "@/components/charts/ApexLineWeeklyTrend";
import PermissionChecker from "@/components/PermissionChecker";
import StreamNotesReg from "@/components/bits/StreamNotesReg";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'StreamingRegional',
	components: {
		ALTable,
		StreamNotesReg,
		PermissionChecker,
		ApexLineWeeklyTrend,
		ChartHolderDash,
		DatePicker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-reg','connections','content-metro'],
			getChartsBtnWorking: false,
			chartsData: [],
			chartsSource: [],
			chartsTitle: [],
			showStationSelectOverlay: false,

			stations: [],
			monthstarts: [],
			monthends: [],
			weekstarts: [],
			weekends: [],

			chart: {},
			statistics: [
				{id: 'tlh', name: 'Total Hours'},
				{id: 'aas', name: 'Avg Active Sess'},
				{id: 'cume', name: 'Cume / Reach (IAB)'},
				{id: 'ss', name: 'Total Sessions'},
			],
			dayparts: [
				{id: 'overall', name: 'All Times (Mon-Sun)'},
				{id: 'bfast', name: 'Breakfast 530a-9a M-F'},
				{id: 'workday', name: 'Workday 9a-4p M-F'},
				{id: 'drive', name: 'Drive 4p-6p M-F'},
			],
			periods: [
				{id: 'w', name: 'Weekly'},
				{id: 'm', name: 'Monthly'},
				{id: 'd', name: 'Daily'}
			],
			distributors: [
				{id: 'all', name: 'All'},
				{id: 'iheart', name: 'iHeartRadio'},
				{id: 'noniheart', name: 'Not iHeartRadio'},
				// {id: 'radioapp', name: 'RadioApp'},
				// {id: 'tunein', name: 'TuneIn'},
				// {id: 'triton-web', name: 'Triton Web'},
			],

			chosenStations: [],
			chosenDaypart: 'overall',
			chosenStatistic: 'tlh',
			chosenPeriod: 'm',
			chosenStartDay: "",
			chosenEndDay: '',
			startdayselect: '',
			enddayselect: '',
			chosenDist: 'all',
			groupStationsTogether: false,

			defaultWeekStart: '',
			defaultWeekEnd: '',
			defaultMonthStart: '',
			defaultMonthEnd: '',

			chartTitle: '',
		}
	},
	computed: {
		tableColumnHeaders() {
			let c = [{title: 'Station', sortable: true, fldid: 'name'}]
			//{title: 'Current Pageviews', sortable: true, fldid: 'mthdl', align: 'right', numtype: 'number'},
			if(this.chartsData.streamstntrnd && this.chartsData.streamstntrnd.length > 0) {
				let first = this.chartsData.streamstntrnd[0]
				for (let ob of first.data) {
					c.push({title: ob['x'], fldid: ob['x'], sortable: true, align: 'right', numtype: 'number'})
				}
			}
			return c
		},
		rowData() {
			let d = []
			if(!this.chartsData.streamstntrnd) return d
			for(let row of this.chartsData.streamstntrnd) {
				let r = {name: row.name}
				if(row.data && row.data.length > 0) {
					for (let ob of row.data) {
						r[ob['x']] = ob['y']
					}
					d.push(r)
				}
			}
			return d
		},
		periodOptions() {
			return this.periods;
		},
		daypartOptions() {
			return this.dayparts;
		},
		startdayselects() {
			if(this.chosenPeriod === 'm') return this.monthstarts;
			else return this.weekstarts;
		},
		enddayselects() {
			if(this.chosenPeriod === 'm') return this.monthends;
			else return this.weekends;
		},
		initialObjectsReceived() {
			return this.store.initialObjectsReceived;
		},
		stationsOrdered() {
			let stns = this.stations
			//order stns array of objects by nicename //_GB_Total should be first
			stns.sort(function(a,b) {
				if(a.nice.toLowerCase() < b.nice.toLowerCase()) return -1;
				if(a.nice.toLowerCase() > b.nice.toLowerCase()) return 1;
				return 0;
			});
			stns = stns.filter(item => item.name !== '_GB_Total') //remove the total station from array
			stns.unshift(this.stations.find(item => item.name === '_GB_Total')) //add total back to the start of the array
			return stns
		},
	},
	methods: {
		selectAllStations(type) {
			if(type === 'metro') {
				this.chosenStations = this.stations.filter(item => item.isReg === 0)
				this.groupStationsTogether = true
			}
			else if (type === 'regional') {
				this.chosenStations = this.stations.filter(item => item.isReg === 1)
				this.groupStationsTogether = true
			}
			else if(type === 'all') {
				this.chosenStations = this.stations
				this.groupStationsTogether = true
			}
		},
		getChartData() {
			this.getChartsBtnWorking = true;
			this.showStationSelectOverlay = false;
			let stnarr = [];
			for(let s of this.chosenStations) {
				stnarr.push(s.name);
			}
			var str = "statistic="+this.chosenStatistic+"&stncode="+stnarr.join("|")+"&daypart=";
			str += this.chosenDaypart+"&ptype="+this.chosenPeriod;
			let gt = 1;
			if(this.groupStationsTogether === false) gt = 0;
			str += '&grouptogether='+gt;
			var startday = "";
			var endday = "";
			if(this.chosenPeriod === 'd') {
				startday = this.chosenStartDay;
				endday = this.chosenEndDay;
			}
			else { //weekly or monthly
				startday = this.startdayselect;
				endday = this.enddayselect;
				let startArr = startday.split('-'); //reverse from dd-mm-yyyy to yyyy-mm-dd
				startday = startArr[2]+'-'+startArr[1]+'-'+startArr[0];
				let endArr = endday.split('-'); //reverse from dd-mm-yyyy to yyyy-mm-dd
				endday = endArr[2]+'-'+endArr[1]+'-'+endArr[0];
			}

			str += "&startdate=" + startday + "&enddate=" + endday + "&dist=" + this.chosenDist;
			var self = this;
			var targ = "/api/streaming-get-gb.php?"+str;
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.chartsTitle != null) {
						self.chartTitle = ret.chartsTitle.streamstntrnd;
					}
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		getMeta() {
			var self = this;
			var targ = "/api/streaming-get-meta.php?gb=1";
			axios.get(targ).then(function (response) {
				let newidstart = 999900
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.appinfo != null) {
						var a = ret.appinfo;
						let retstations = a['streamingStationJson'];
						let stns = [];
						for(let i=0; i<retstations.length; i++) {
							let mystn = retstations[i];
							let myid = mystn.id
							if(myid === 0) {
								myid = newidstart
								newidstart++
							}
							stns.push({id: myid, name: mystn.name, nice: mystn.nice, isReg: mystn.isReg});
						}
						stns = stns.sort(function(a, b){
							if(a.name < b.name) { return -1; }
							if(a.name > b.name) { return 1; }
							return 0;
						});
						stns.splice(0, 0, {id: 9999, name: '_GB_Total', nice: 'ARN Total'}); //add to front of array
						self.stations = stns;
						self.monthstarts = a['streamingMonthStarts'];
						self.monthends = a['streamingMonthEnds'];
						self.weekstarts = a['streamingWeekStartsGB'];
						self.weekends = a['streamingWeekEndsGB'];
						self.chosenStartDay = a['streamingDayStart'];
						self.chosenEndDay = a['streamingDayEnd'];
						self.startdayselect = a['streamingDefaultWeekStart'];
						self.enddayselect = a['streamingDefaultWeekEnd'];

						self.defaultWeekStart = a['streamingDefaultWeekStartGB'];
						self.defaultWeekEnd = a['streamingDefaultWeekEndGB'];
						self.defaultMonthStart = a['streamingDefaultMonthStart'];
						self.defaultMonthEnd = a['streamingDefaultMonthEnd'];

						self.startdayselect = self.defaultMonthStart;
						self.enddayselect = self.defaultMonthEnd;

						if(self.store.user.id === 34) { //34=whippy, gc only for him.
							self.chosenStations = self.stations.filter(item => item.id === 200); //set to HT
						}
						else self.chosenStations = self.stations.filter(item => item.id === 9999);

						self.getChartData();
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		chosenPeriod() {
			if(this.chosenPeriod === 'w') {
				this.startdayselect = this.defaultWeekStart;
				this.enddayselect = this.defaultWeekEnd;
			}
			else if(this.chosenPeriod === 'm') {
				this.startdayselect = this.defaultMonthStart;
				this.enddayselect = this.defaultMonthEnd;
			}
			this.getChartData();
		},
		initialObjectsReceived() {
			if(this.initialObjectsReceived === true) {
				this.getMeta();
			}
		},
		chosenStation() { this.getChartData(); },
		chosenStatistic() { this.getChartData(); },
		chosenDaypart() { this.getChartData(); },
	},
	mounted() {

	},
	created() {
		if(this.store.initialObjectsReceived) {
			this.getMeta();
		}
		document.title = "ARN Streaming";
	},
}
</script>

<style scoped>
.top-selections {
	display: flex;
	justify-content: flex-start;
	gap: 15px;
}
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 7px 10px;
	border-radius: 5px;
}
.netshortcut:hover {
	background-color: #DDD;
}
.stationselect-popup {
	width: 70%;
	height: 700px;
	margin: 40px auto;
	position: relative;
}
.stationselect-popup.whitebg {
	background-color: #FFF;
	overflow-y: auto;
}
.popup-topbar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	border-bottom: 1px solid rgb(204, 204, 204);
	color: #000;
	padding: 0 20px;
	width: 100%;
	height: 40px;
}
.closer {
	font-size: 18px;
	cursor: pointer;
}
.closer:hover i {
	color: #888;
}
.popup-insides {
	width: 100%;
	padding: 20px;
	height: calc(100% - 40px);
	margin-top: 40px;
	overflow-y: auto;
}

@media screen and (max-width: 1450px) {
	.top-selections {
		flex-wrap: wrap;
	}
}
</style>
