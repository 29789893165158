<template>
	<div id="appportal" :class="{leftmenuhidden : leftmenuhidden}" @click="catchMouseClickAnywhere">

		<AlertTop v-bind:obj="store.kalert"></AlertTop>

		<div v-if="routeIsInsideApp && store.user.email.length > 0" class="leftmenu">
			<div class="top-nav-hold">
				<div style="display: flex; align-items: center; justify-content: flex-start; height: 100%;">
					<div class="hamburger-holder" @click="toggleLeftMenu">
						<i class="mdi mdi-menu purple" style="font-size: 24px;"></i>
					</div>
					<div class="leftmnenu-logo-holder">
						<router-link to="/home">
							<img src="/public-files/arn-logo.svg" style="cursor:pointer; height: 54px;"/>
						</router-link>
					</div>
				</div>

			</div>
			<div>
				<div style="width: 100%;">
					<router-link class="leftmenu-item" to="/home" :class="{active : leftRouteMatch('home')}" title="Home">
						<div class="icon-hold"><i class="mdi mdi-home"></i></div>
						<p>Home</p>
					</router-link>
					<router-link class="leftmenu-item" to="/arn-markets" :class="{active : leftRouteMatch('arnmarkets')}" title="Stations & Markets">
						<div class="icon-hold"><i class="mdi mdi-handshake"></i></div>
						<p>Stations & Markets</p>
					</router-link>
					<router-link class="leftmenu-item" to="/gfk-ratings/charts" :class="{active : leftRouteMatch('gfk')}" title="GFK Ratings Charts">
						<div class="icon-hold"><i class="mdi mdi-video-input-antenna"></i></div>
						<p>GFK Ratings</p>
					</router-link>
					<router-link class="leftmenu-item" to="/xtra-ratings" :class="{active : leftRouteMatch('xtra')}" title="Xtra Ratings Charts">
						<div class="icon-hold"><i class="mdi mdi-chart-line"></i></div>
						<p>Xtra Ratings</p>
					</router-link>
					<router-link v-if="userHasPermission(['elt','connections','content-metro','content-reg'])" class="leftmenu-item" :to="streamingUserUrl" :class="{active : leftRouteMatch('streaming')}" title="Streaming">
						<div class="icon-hold"><i class="mdi mdi-antenna"></i></div>
						<p>Streaming</p>
					</router-link>
					<router-link v-if="userHasPermission(['elt','connections','content-metro','podcast'])" class="leftmenu-item" to="/podcasts/dashboard" :class="{active : leftRouteMatch('podcasting')}" title="Podcasts">
						<div class="icon-hold"><i class="mdi mdi-headphones"></i></div>
						<p>Podcasts</p>
					</router-link>
					<router-link v-if="userHasPermission(['elt','connections','content-metro'])" class="leftmenu-item" to="/brand-health/kiis1065" :class="{active : leftRouteMatch('brandhealth')}" title="Brand Health">
						<div class="icon-hold"><i class="mdi mdi-cards-heart"></i></div>
						<p>Brand Health</p>
					</router-link>
					<router-link class="leftmenu-item" to="/iheart-landing" title="iHeartRadio (Chaplin)" :class="{active : leftRouteMatch('iheart')}">
						<div class="icon-hold"><i class="mdi mdi-database-settings-outline"></i></div>
						<p>iHeartRadio</p>
					</router-link>
					<router-link class="leftmenu-item" to="/web/chart-explorer" :class="{active : leftRouteMatch('web')}" title="Web">
						<div class="icon-hold"><i class="mdi mdi-web"></i></div>
						<p>Web Metrics</p>
					</router-link>
					<router-link v-if="userHasPermission(['elt','connections','cada'])" class="leftmenu-item" to="/cada-dashboard" :class="{active : leftRouteMatch('cada')}" title="CADA Dashboard">
						<div class="icon-hold">
							<img v-if="leftRouteMatch('cada')" src="/public-files/cada-c-wht.png" style="height: 16px;margin-top:-2px;">
							<img v-else src="/public-files/cada-c-blk.png" style="height: 16px;margin-top:-2px;">
						</div>
						<p>CADA</p>
					</router-link>
					<router-link class="leftmenu-item" to="/kix-country" :class="{active : leftRouteMatch('kix')}" title="KIX Country">
						<div class="icon-hold"><i class="mdi mdi-guitar-acoustic"></i></div>
						<p>KIX Country</p>
					</router-link>
					<router-link v-if="userHasPermission(['elt','connections'])" class="leftmenu-item" to="/marketing" :class="{active : leftRouteMatch('marketing')}" title="Marketing Tools">
						<div class="icon-hold"><i class="mdi mdi-bullhorn"></i></div>
						<p>Marketing</p>
					</router-link>
					<router-link class="leftmenu-item" to="/sales-tools" :class="{active : leftRouteMatch('salestools')}" title="Sales Tools">
						<div class="icon-hold"><i class="mdi mdi-map-marker-outline"></i></div>
						<p>Sales Tools</p>
					</router-link>
					<div class="leftmenu-item" @click="$router.push('/logout')" title="Log Out">
						<div class="icon-hold"><i class="mdi mdi-logout"></i></div>
						<p>Logout</p>
					</div>
				</div>
			</div>

		</div>

		<div v-if="routeIsInsideApp && store.user.email.length > 0" class="mainbody">

			<div class="top-nav-hold top-nav-rhs">
				<div class="main-inside" style="margin-bottom: 0; padding-bottom: 0; padding-top: 0; display: flex; justify-content: space-between; align-items: center;">
					<div class="nav-items" style="display: flex; justify-content: flex-end;">
						<div v-if="activeSection === 'podcasts'" style="display: flex; justify-content: flex-end;">
							<router-link to="/podcasts/dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/dashboard'}">Dashboard</span>
							</router-link>
							<router-link to="/podcasts/categories">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/categories'}">Categories</span>
							</router-link>
							<router-link to="/podcasts/podcast-ranker">
								<span class="link-btn" :class="{'active' : isTopMatchCRARanker === true}">CRA Ranker</span>
							</router-link>
							<router-link v-if="userHasPermission(['podcast-admin'])" to="/podcasts/shows">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/shows'}">Omny Shows</span>
							</router-link>
							<router-link v-if="userHasPermission(['elt','connections','content-metro','podcast'])" to="/podcasts/show-detail">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/show-detail'}">Show Detail</span>
							</router-link>
							<router-link to="/podcasts/on-demand-radio">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/on-demand-radio'}">On-Demand Radio</span>
							</router-link>
							<router-link to="/podcasts/profile-indexes">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/profile-indexes'}">Indexing</span>
							</router-link>
							<router-link to="/podcasts/genre-indexes">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/genre-indexes'}">Genres</span>
							</router-link>
<!--							<router-link v-if="userHasPermission(['elt','connections','podcast-admin'])" to="/podcasts/admin-dashboard">-->
<!--								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/podcasts/admin-dashboard'}">Admin</span>-->
<!--							</router-link>-->
						</div>
						<div v-if="activeSection === 'gfk-ratings'" style="display: flex; justify-content: flex-end;">
							<router-link to="/gfk-ratings/charts">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/gfk-ratings/charts'}">Chart Lookup</span>
							</router-link>
							<router-link to="/gfk-ratings/highlights">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/gfk-ratings/highlights'}">Station Highlights</span>
							</router-link>
							<a href="https://dashboards.audology.app/arn-7dk349fafg-dazdpqofjf8fs" target="_blank" style="text-decoration: none;">
								<span class="link-btn">Content Dashboard</span>
							</a>
						</div>
						<div v-if="activeSection === 'streaming'" style="display: flex; justify-content: flex-end;">
							<router-link v-if="userHasPermission(['elt','connections','content-metro','content-reg'])" to="/streaming/realtime">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/streaming/realtime'}">Realtime</span>
							</router-link>
							<router-link v-if="userHasPermission(['elt','connections','content-metro','content-reg'])" to="/streaming/realtime-trend">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/streaming/realtime-trend'}">Realtime Trend</span>
							</router-link>
							<router-link v-if="userHasPermission(['elt','connections','content-metro','content-reg'])" to="/streaming/monthly-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/streaming/monthly-dashboard'}">Monthly Dashboard</span>
							</router-link>
							<router-link v-if="userHasPermission(['elt','connections','content-metro','content-reg'])" to="/streaming/trending">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/streaming/trending'}">Trending</span>
							</router-link>
<!--							<router-link v-if="userHasPermission(['elt','connections','content-metro'])" to="/streaming/ihr-stations">-->
<!--								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/streaming/ihr-stations'}">IHR Stations</span>-->
<!--							</router-link>-->
						</div>
						<div v-if="activeSection === 'brand-tracking'" style="display: flex; justify-content: flex-end;">
							<router-link v-if="userHasPermission(['elt','connections','content-metro'])"  to="/brand-tracking/market-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/brand-tracking/market-dashboard'}">Market Dashboards</span>
							</router-link>
							<router-link v-if="userHasPermission(['elt','connections','content-metro'])"  to="/brand-tracking/iheart-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/brand-tracking/iheart-dashboard'}">iHeart Dashboard</span>
							</router-link>
						</div>
						<div v-if="activeSection === 'arn-markets'" style="display: flex; justify-content: flex-end;">
							<router-link to="/arn-markets">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/arn-markets'}">Markets</span>
							</router-link>
							<router-link  to="/arn-markets/stations">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/arn-markets/stations'}">Stations</span>
							</router-link>
							<router-link  to="/arn-markets/national-cume">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/arn-markets/national-cume'}">National Reach</span>
							</router-link>
							<router-link  to="/arn-markets/station-music-log">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/arn-markets/station-music-log'}">Music Logs</span>
							</router-link>
						</div>
						<div v-if="activeSection === 'web'" style="display: flex; justify-content: flex-end;">
							<router-link  to="/web/chart-explorer">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/web/chart-explorer'}">Chart Explorer</span>
							</router-link>
							<router-link  to="/web/metro-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/web/metro-dashboard'}">Metro</span>
							</router-link>
							<router-link to="/web/regional-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/web/regional-dashboard'}">Regional</span>
							</router-link>
							<router-link to="/web/national-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/web/national-dashboard'}">National</span>
							</router-link>
							<router-link  to="/web/top-pages">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/web/top-pages'}">Top Pages</span>
							</router-link>
						</div>
						<div v-if="activeSection === 'post-campaign-reporting'" style="display: flex; justify-content: flex-end;">
							<router-link to="/post-campaign-reporting">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/post-campaign-reporting'}">PCR Export</span>
							</router-link>
							<router-link to="/post-campaign-reporting/ad-log">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/post-campaign-reporting/ad-log'}">Station Log</span>
							</router-link>
							<router-link v-if="userHasPermission(['pcr-randy'])" to="/post-campaign-reporting/ry-reports">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/post-campaign-reporting/ry-reports'}">R&Y Reports</span>
							</router-link>
							<router-link v-if="userHasPermission(['pcr-randy'])" to="/post-campaign-reporting/revenue-charts">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/post-campaign-reporting/revenue-charts'}">Revenue Charts</span>
							</router-link>
						</div>
						<div v-if="activeSection === 'iheart'" style="display: flex; justify-content: flex-end;">
							<router-link to="/iheart/dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/iheart/dashboard'}">Dashboard</span>
							</router-link>
							<router-link to="/iheart/playlists-dashboard">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/iheart/playlists-dashboard'}">Playlists</span>
							</router-link>
							<router-link to="/iheart/national-kpi">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/iheart/national-kpi'}">National KPIs</span>
							</router-link>
							<router-link to="/iheart/regional-summary">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/iheart/regional-summary'}">Regional Summary</span>
							</router-link>
							<router-link to="/iheart/chart-explorer">
								<span class="link-btn" :class="{'active' : $router.currentRoute.value.path === '/iheart/chart-explorer'}">Chart Explorer</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<div class="top-back" style="height: 70px; width: 100%;"></div>

			<div class="main-contain">
				<div class="main-inside">

					<router-view></router-view>

				</div>
			</div>

		</div>

		<div v-if="!routeIsInsideApp">
			<router-view></router-view>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AlertTop from "@/components/AlertTop";
export default {
	name: 'App',
	components: {
		AlertTop
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			requestedRoute: '',
			routesOutsideApp: window.routesOutsideApp,
			activeSection: '',
			leftmenuhidden: false,
		}
	},
	computed: {
		routeIsInsideApp() {
			if(this.routesOutsideApp.includes(this.$router.currentRoute.value.path)) return false;
			if(this.$router.currentRoute.value.path.indexOf('/slideshow/') === 0) return false;
			return true;
		},
		streamingUserUrl() {
			if(this.userHasPermission(['elt','connections','content-metro','content-reg'])) {
				return '/streaming/trending';
			}
			return false;
		},
		isTopMatchCRARanker() {
			let cp = this.$router.currentRoute.value.path
			if(cp.indexOf('/podcasts/podcast-ranker') === 0) return true;
			if(cp.indexOf('/podcasts/podcast-ranker-publishers') === 0) return true;
			if(cp.indexOf('/podcasts/show-visual-ranker') === 0) return true;
			if(cp.indexOf('/podcasts/top-movers') === 0) return true;
			return false
		},
	},
	methods: {
		leftRouteMatch(matchstr) {
			let arr = [];
			let cp = this.$router.currentRoute.value.path;
			if(matchstr === 'podcasting') {
				if(arr.includes(cp) || cp.indexOf('/podcasts/') === 0) return true;
			}
			else if(matchstr === 'gfk') {
				if(arr.includes(cp) || cp.indexOf('/gfk-ratings') === 0) return true;
			}
			else if(matchstr === 'xtra') {
				if(arr.includes(cp) || cp.indexOf('/xtra-ratings') === 0) return true;
			}
			else if(matchstr === 'arnmarkets') {
				if(arr.includes(cp) || cp.indexOf('/arn-markets') === 0) return true;
			}
			else if(matchstr === 'brandhealth') {
				if(arr.includes(cp) || cp.indexOf('/brand-health') === 0) return true;
			}
			// else if(matchstr === 'brandtracking') {
			// 	if(arr.includes(cp) || cp.indexOf('/brand-tracking/') === 0) return true;
			// }
			else if(matchstr === 'marketing') {
				if(arr.includes(cp) || cp.indexOf('/marketing') === 0) return true;
				if(arr.includes(cp) || cp.indexOf('/brand-tracking') === 0) return true;
			}
			else if(matchstr === 'streaming') {
				if(arr.includes(cp) || cp.indexOf('/streaming/') === 0) return true;
			}
			else if(matchstr === 'kix') {
				if(arr.includes(cp) || cp.indexOf('/kix-country') === 0) return true;
			}
			else if(matchstr === 'home') {
				if(arr.includes(cp) || cp.indexOf('/home') === 0) return true;
			}
			else if(matchstr === 'web') {
				if(arr.includes(cp) || cp.indexOf('/web/') === 0) return true;
			}
			else if(matchstr === 'cada') {
				if(arr.includes(cp) || cp.indexOf('/cada') === 0) return true;
			}
			// else if(matchstr === 'postcampaign') {
			// 	if(arr.includes(cp) || cp.indexOf('/post-campaign') === 0) return true;
			// }
			else if(matchstr === 'salestools') {
				if(arr.includes(cp) || cp.indexOf('/sales-tools') === 0) return true;
				if(arr.includes(cp) || cp.indexOf('/post-campaign') === 0) return true;
			}
			else if(matchstr === 'iheart') {
				if(arr.includes(cp) || cp.indexOf('/iheart') === 0) return true;
			}
			return false;
		},
		sesscheck() {
			let self = this;
			axios.get(
				"/post/sesscheck.php"
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				// if(ret.sessinfo['sessionok'] === 0 && self.routesOutsideApp.indexOf(self.$router.currentRoute.value.path) === -1) { //transfer to login screen
				if(ret.sessinfo['sessionok'] === 0 && self.routeIsInsideApp === true) { //transfer to login screen
					if(self.$router.currentRoute.value.path !== '/login') {
						if(self.requestedRoute.length > 2 && self.requestedRoute !== "/") {
							window.location = '/login?ref='+self.requestedRoute;
						}
						else {
							window.location = '/login';
						}
					}
				}
				// else if(!self.routesOutsideApp.includes(self.$router.currentRoute.value.path)) {
				else if(self.routeIsInsideApp === true) {
					if(ret.user) {
						self.setUser(ret.user);
						//self.getMarketData(); //get our initial required objects eg market/station lists
						self.getMarketDataAudology()
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		updateActiveSection(path) {
			var adjustedstr = path.substring(1); //ignore the first slash (at position zero)
			var slashpos = adjustedstr.indexOf("/");
			var sec = "";
			if(slashpos <= -1) sec = adjustedstr;
			else sec = adjustedstr.substr(0, slashpos);
			this.activeSection = sec;
		},
		toggleLeftMenu() {
			document.querySelector('.main-contain').classList.add('hidden');
			document.querySelector('.main-contain').classList.add('displaynone');
			document.querySelectorAll('.leftmenu-item p').forEach((item) => item.classList.add('hidden'));
			if(this.leftmenuhidden === true) {
				this.leftmenuhidden = false;
			}
			else {
				this.leftmenuhidden = true;
			}
			setTimeout(function() {
				document.querySelector('.main-contain').classList.remove('displaynone');
			}.bind(this), 100);
			setTimeout(function() {
				document.querySelector('.main-contain').classList.remove('hidden');
				if(this.leftmenuhidden === false) {
					document.querySelectorAll('.leftmenu-item p').forEach((item) => item.classList.remove('hidden'));
				}
			}.bind(this), 500);
		},
		getMarketDataAudology() {
			let self = this
			let postjson = JSON.stringify({
				apicreds: this.store.audologyAPICreds,
			})
			axios.post(this.store.audologyAPIUrl+'get-objects', postjson).then(function (response) {
				if(response.data.error) {
					return
				}
				self.store.stations = response.data.stations
				self.store.demos = response.data.demographics
				self.store.surveys = response.data.surveys
				//loop through stations and overload market tags for ARN, SCA etc
				for(let m=0; m<response.data.markets.length; m++) {
					let x = {
						owns: [],
						reps: [],
						arnown: false,
						scaown: false,
						arnrep: false,
						scarep: false,
					}
					let mymkt = response.data.markets[m];
					let stnmkts = response.data.stations.filter(item => mymkt.id === item.mktid && item.isOther === false);
					for(let s=0; s<stnmkts.length; s++) {
						let mystn = stnmkts[s];
						if(!x.owns.includes(mystn.netgroupown) && mystn.netgroupown.length > 0) x.owns.push(mystn.netgroupown);
						if(!x.reps.includes(mystn.repnetgroup) && mystn.repnetgroup.length > 0) x.reps.push(mystn.repnetgroup);
						if(mystn.netgroupown === 'arn') x.arnown = true;
						if(mystn.netgroupown === 'sca') x.scaown = true;
						if(mystn.repnetgroup === 'arn') x.arnrep = true;
						if(mystn.repnetgroup === 'sca') x.scarep = true;
					}
					response.data.markets[m].ownership = x;
				}
				self.store.markets = response.data.markets
				self.store.dayparts = response.data.dayparts
				self.store.qtrHrObs = response.data.qtrHrs
				self.store.statistics = response.data.statistics
				self.store.initialObjectsReceived = true
				self.getAudologyNationalCumes()
			}).catch(function (error) {
				console.log(error)
			});
		},
		catchMouseClickAnywhere() {
			if(this.store.mouseClickAnywhere === false) {
				this.store.mouseClickAnywhere = true
				setTimeout(function () {
					this.store.mouseClickAnywhere = false
				}.bind(this), 50)
			}
		},
	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.value.path;
		//get today's date YYYY-MM-DD
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth()+1; //January is 0!
		let yyyy = today.getFullYear();
		if(dd<10) dd='0'+dd;
		if(mm<10) mm='0'+mm;
		this.store.natCumeDateToUse = yyyy+'-'+mm+'-'+dd;
		this.sesscheck();
		this.updateActiveSection(this.$router.currentRoute.value.path);
		this.sendlogging(this.$router.currentRoute.value.path);
	},
	created() {
		//handle incoming GET parameters
		// let urlparams = this.$route.query;
		// if(urlparams.e !== undefined) {
		// 	//this.store.commit('setRegistrationEmail', urlparams.e);
		// }
	},
	watch: {
		$route (to){
			this.updateActiveSection(to.path);
			this.sendlogging(to.path);
		}
	},
}
</script>

<style>
#appportal {
	min-height: 100vh;
}
.leftmenu {
	width: 210px;
	flex-shrink: 0;
	background-color: #FFF;
	height: 100%;
	min-height: 100vh;
	position: fixed;
	z-index: 1;
	/*box-shadow: 1px 7px 20px 5px rgba(0,0,0,0.1);*/
	border-right: 1px solid #DDD;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}
.leftmenuhidden .leftmenu {
	width: 70px;
}
.mainbody {
	width: calc(100% - 210px);
	margin-left: 210px;
	position: relative;
}
.leftmenuhidden .mainbody {
	width: calc(100% - 70px);
	margin-left: 70px;
}
.top-nav-hold {
	height: 70px;
	background-color: #f1eff3;
	border-bottom: 1px solid #dad4e0;
	transition: all 0.3s ease-in-out;
}
.leftmenu .top-nav-hold {
	background-color: #FFF;
	border-bottom: 1px solid #DDD;
}
.mainbody .top-nav-hold {
	position: fixed;
	z-index: 12;
	padding: 0 40px;
	width: calc(100% - 166px);
}
.leftmenuhidden .mainbody .top-nav-hold {
	width: calc(100% - 70px);
}
.top-nav-hold.top-nav-rhs {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.leftmnenu-logo-holder {
	opacity: 1;
	transition: opacity 0.1s ease-in-out;
	transition-delay: 0.3s;
}
.leftmenuhidden .leftmnenu-logo-holder {
	opacity: 0;
	visibility: hidden;
}
.purple {
	color: #553AA9;
}
.hamburger-holder {
	margin-left: 16px;
	border-radius: 10px;
	padding: 5px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}
.hamburger-holder:hover {
	background-color: #DDD;
}
.leftmenu-item {
	border-bottom: 1px solid #DDD;
	width: 100%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	display: flex;
	height: 42px;
	align-items: center;
}
.leftmenu-item:hover, .leftmenu-item.active {
	color: #FFF;
	background-color: #4E39A3;
}
.leftmenu-item .icon-hold {
	font-size: 20px;
	margin: 0 10px 0 23px;
}
.leftmenu-item p {
	font-size: 14px;
	font-weight: 500;
}
.leftmenu-item p.hidden {
	display: none;
}
.main-contain.displaynone {
	display: none;
}
.main-contain.hidden {
	visibility: hidden;
}
</style>
