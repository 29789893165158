<template>
	<div class="notes">
		<p>We do not receive raw logs from our provider (Triton).
		Monthly results are calculated by Triton and retrieved by ARN for this dashboard by the 5th of the month (pending any potential delays at the Triton end).</p>
	</div>
</template>

<script>

export default {
	name: "StreamNotesReg",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.notes {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #DDD;
	background-color: #EEE;
	color: #444;
	font-size: 13px;
}
</style>