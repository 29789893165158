<template>
	<div>

		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>

		<div v-if="userHasPermission(viewPerms)" class="chart-rows">
			<div class="chart-holder-section">
				<ChartHolderDash
						:charttitle="chartsTitle.realtime_trend"
						:source="chartsSource.realtime_trend" :height="600">
					<template v-slot:headerRight>
						<div style="display: flex; align-items: center; gap: 10px;">
							<div style="width: 200px; display: flex; align-items: center; z-index: 10;">
								<select class="select-styled" v-model="chosenStation">
									<option v-for="opt in stationsFiltered" :key="opt.id" :value="opt.id">{{opt.name}}</option>
								</select>
							</div>

							<date-picker class="dater-tr" style="width: 140px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
							<span style="margin: 0 5px;">to</span>
							<date-picker class="dater-tr" style="width: 140px;" v-model:value="enddate" valueType="format" format="YYYY-MM-DD"></date-picker>

							<div style="width: 120px; display: flex; align-items: center; z-index: 10;">
								<select class="select-styled" v-model="cadence">
									<option value="d">Daily</option>
									<option value="w">Weekly</option>
									<option value="m">Monthly</option>
								</select>
							</div>
							<div style="width: 180px; display: flex; align-items: center; z-index: 10;">
								<select class="select-styled" v-model="daypart">
									<option value="overall">Overall</option>
									<option value="bf530_9">Bfast M-F 5:30-9a</option>
									<option value="bf6_9">Bfast M-F 6a-9a</option>
									<option value="bf6_10">Bfast M-F 6a-10a</option>
									<option value="dr4_6">Drive M-F 4p-6p</option>
								</select>
							</div>
							<div style="width: 130px; display: flex; align-items: center; z-index: 10;">
								<select class="select-styled" v-model="compareWith">
									<option value="none">No Compare</option>
									<option value="priorYear">vs Prior Yr</option>
								</select>
							</div>
							<div><span class="primary-btn" @click="getChartData">Fetch</span></div>
						</div>
					</template>
					<template v-slot:default>
						<apexchart v-if="isLoading === false && chartsData.realtime_trend && chartsData.realtime_trend.length > 0" width="99%" height="100%" type="bar"
							:options="chartoptions" :series="chartsData.realtime_trend"></apexchart>
						<ChartLoaderInsert v-if="isLoading"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import PermissionChecker from "@/components/PermissionChecker";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert.vue";

export default {
	name: 'StreamingRealtimeTrend',
	components: {
		ChartLoaderInsert,
		PermissionChecker,
		ChartHolderDash,
		DatePicker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','content-reg','connections'],
			getChartsBtnWorking: false,
			chartsData: [],
			chartsSource: [],
			chartsTitle: [],
			tablehtml: '',
			isLoading: false,

			stations: [],
			monthstarts: [],
			monthends: [],
			weekstarts: [],
			weekends: [],

			chart: {},

			chosenStation: 0,
			cadence: 'd',
			daypart: 'overall', //overall, bf530_9, bf6_10, bf6_9
			startdate: '',
			enddate: '',
			compareWith: 'none',

			chartoptions: {
				chart: {
					id: 'Realtime Sessions',
					toolbar: {
						show: true,
						export: {
							csv: {
								headerCategory: 'Time',
								filename: 'Realtime_Sessions',
								headerValue: 'value',
								dateFormatter(timestamp) {
									return timestamp; //new Date(timestamp).toDateString()
								}
							}
						},
					},
					animations: {
						enabled: false
					},
					type: 'bar',
				},
				fill: {
					// gradient: {
					// 	opacityFrom: 0.4,
					// 	opacityTo: 0.2,
					// },
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: true,
						datetimeUTC: false, //use the exact (local for station) time passed by PHP
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					// labels: {
					// 	formatter: (value) => {
					// 	if(value === null) return null
					// 	return value.toLocaleString()
					// 	},
					// },
				},
				colors: ['#553AA9','#7bb7db'],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0,
					enabled: false,
				},
				tooltip: {
					x: {
						format: 'dd MMMM yyyy HH:mm'
					}
				},
			},
		}
	},
	computed: {
		stationsFiltered() {
			return this.stations.filter(item => item.id !== 9999)
		},
	},
	methods: {
		getChartData() {
			this.isLoading = true
			this.getChartsBtnWorking = true
			let self = this
			let targ = "/api/streaming/get-realtime-trend.php?station="+this.chosenStation+"&cadence="+this.cadence+"&daypart="+this.daypart
			targ += "&compareWith="+this.compareWith+"&startDate="+this.startdate+"&endDate="+this.enddate
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource
					}
					if (ret.chartsTitle != null) {
						self.chartsTitle = ret.chartsTitle
					}
				}
				self.getChartsBtnWorking = false
				self.isLoading = false
			}).catch(function (error) {
				console.log(error)
				self.isLoading = false
			});
		},
		getMeta() {
			let self = this
			let targ = "/api/streaming-get-meta.php?includeAggregates=1"
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.appinfo != null) {
						let a = ret.appinfo
						self.stations = a['streamingRealtimeStations']
						self.chosenStation = 35954 //self.stations[0].id
					}
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {
		chosenStation() {
			this.getChartData()
		},
		cadence() {
			this.getChartData()
		},
		daypart() {
			this.getChartData()
		},
		compareWith() {
			this.getChartData()
		},
		startdate() {
			this.getChartData()
		},
		enddate() {
			this.getChartData()
		},
	},
	props: {
		mychartdata: Object
	},
	mounted() {
		this.enddate = this.dateNow()
		let d = new Date()
		d.setMonth(d.getMonth() - 6)
		this.startdate = d.toISOString().split('T')[0]
	},
	created() {
		this.getMeta();
		document.title = "ARN Realtime Streaming Trends"
	}
}
</script>

<style scoped>
.chart-holder-section {
	width: 100%;
}

</style>
